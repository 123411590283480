<script lang="ts">
  import { ModalDialog } from '@pids/shared-component';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';

  const dispatch = createEventDispatcher<{
    confirm: void;
    cancel: void;
  }>();

  export let name: string;
  export let title: string;
  export let open = false;

  const dispatchConfirm = () => {
    dispatch('confirm');
  };

  const dispatchCancel = () => {
    dispatch('cancel');
  };
</script>

<ModalDialog
  {title}
  bind:active={open}
  okHandler={dispatchConfirm}
  cancelHandler={dispatchCancel}
  showCancel={true}
  okText={$_('button.delete')}
  cancelText={$_('button.cancel')}>
  <p>
    <slot name="warning" />
  </p>
  <p class="py-2">
    <b>{name}</b>
  </p>
  <p>
    <slot name="confirm" />
  </p>
</ModalDialog>
