<script lang="ts">
  import { toHtmlId } from 'src/util/htmlUtils';
  import { ClearIcon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';

  export let id: string;
  export let value: string | undefined = undefined;
  export let required = false;

  let interacted = false;

  const inputId = toHtmlId(id, 'text-input');

  const handleClear = () => {
    value = undefined;
  };

  $: showClear = Boolean(value);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  label.disabled {
    color: $medium-emphasis;
  }

  label.invalid {
    color: $alert-error !important;
  }

  input.invalid {
    outline-color: $alert-error !important;
    outline: 0.0625rem solid;
    outline-offset: 0.06rem;
  }

  .field-text input {
    display: inline-block;
  }

  .text-field-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .clear-icon {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  button {
    border: none;
    padding: 0;
    text-align: left;
    font-size: inherit;
    background-color: transparent;
    width: auto;
    cursor: pointer;
    pointer-events: all;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.35rem;
    top: 0;
  }

  input.clearable {
    padding-right: 1.75rem;
  }
</style>

<div class="field-text">
  <label for={inputId} class="has-text-grey text-field-label">
    <slot />
  </label>
  <div class="control" style="position: relative;">
    <input
      id={inputId}
      class="input"
      class:invalid={interacted && required && !value}
      class:clearable={showClear}
      on:blur={() => (interacted = true)}
      bind:value
      type="text" />
    {#if showClear}
      <button type="button" class="clear-icon" on:click={handleClear}>
        <ClearIcon />
      </button>
    {/if}
  </div>
</div>
