<script lang="ts">
  import DateIntervalCheckboxInput from 'src/components/shared/DateIntervalCheckboxInput.svelte';
  import type { CreateEditMarketingText } from 'src/generated/service/cache-api';
  import { _ } from 'svelte-i18n';

  export let value: Partial<CreateEditMarketingText>;
</script>

<div>
  <p class="menu-label is-size-7 has-text-weight-semibold has-text-black mb-2">
    {$_('marketing-text.dialog.config.validity')}
  </p>

  <DateIntervalCheckboxInput id="marketing-text-validity" bind:fromDate={value.startDate} bind:toDate={value.endDate}>
    <svelte:fragment slot="from-label">{$_('marketing-text.dialog.config.validity.from')}</svelte:fragment>
    <svelte:fragment slot="to-label">{$_('marketing-text.dialog.config.validity.to')}</svelte:fragment>
  </DateIntervalCheckboxInput>
</div>
