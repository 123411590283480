<script lang="ts">
  import type { PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { MarketingTextFilterCriteria } from 'src/components/pages/marketing/marketingTextService';
  import type { CreateEditMarketingText, MarketingTextItem, Pagination } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { _ } from 'svelte-i18n';
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';
  import { Button, NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { MarketingTextSort } from 'src/components/pages/marketing/marketingTextSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import {
    createMarketingText,
    deleteMarketingText,
    fetchMarketingTexts,
    getMarketingText,
    updateMarketingText
  } from 'src/components/pages/marketing/marketingTextService';
  import type { MarketingTextDelete, MarketingTextEdit } from 'src/components/pages/marketing/MarketingTable.svelte';
  import MarketingTable from 'src/components/pages/marketing/MarketingTable.svelte';
  import MarketingFilter from 'src/components/pages/marketing/MarketingFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import MarketingTextModalDialog from 'src/components/pages/marketing/MarketingTextModalDialog.svelte';
  import DeleteMarketingModalDialog from 'src/components/pages/marketing/DeleteMarketingModalDialog.svelte';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${MarketingTextSort.Name},asc`
  };

  const sortFunction: SortFunction = sortBy(MarketingTextSort.Name);
  let paginationParams: PaginationParams = initialPagination;

  let filter = writable<MarketingTextFilterCriteria>({});
  let results: MarketingTextItem[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  let showSaveDialog = false;
  let marketingTextId: number | undefined = undefined;
  let marketingTextData: CreateEditMarketingText | undefined = undefined;

  let showDeleteDialog = false;
  let marketingTextName: string | undefined;

  const resultsQuery = createQuery([], signal => fetchMarketingTexts($filter, paginationParams, { signal }), false);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    clearFilters();

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: MarketingTextSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const clearFilters = () => {
    $filter = {};
  };

  const openSaveDialog = async (event?: SvelteEvent<MarketingTextEdit>) => {
    if (event) {
      marketingTextId = event.detail.id;
      const marketingText = await getMarketingText(marketingTextId);
      if (!marketingText) return;
      marketingTextData = {
        name: marketingText.name,
        trains: marketingText.trains,
        endDate: marketingText.endDate,
        startDate: marketingText.startDate,
        textDe: marketingText.textDe,
        textFr: marketingText.textFr,
        textEn: marketingText.textEn,
        textIt: marketingText.textIt
      };
    }

    showSaveDialog = true;
  };

  const handleSave = async ({ detail: marketingText }: SvelteEvent<CreateEditMarketingText>) => {
    const data = await saveMarketingText(marketingText);

    if (data) loadData();

    closeSaveDialog();
  };

  const saveMarketingText = async (marketingText: CreateEditMarketingText) => {
    if (marketingTextId) {
      return updateMarketingText(marketingTextId, marketingText);
    }

    return createMarketingText(marketingText);
  };

  const closeSaveDialog = () => {
    showSaveDialog = false;
    marketingTextData = undefined;
    marketingTextId = undefined;
  };

  const openDeleteDialog = (e: SvelteEvent<MarketingTextDelete>) => {
    showDeleteDialog = true;
    marketingTextName = e.detail.name;
    marketingTextId = e.detail.id;
  };

  const closeDeleteDialog = () => {
    showDeleteDialog = false;
    marketingTextId = undefined;
    marketingTextName = undefined;
  };

  const handleConfirmDelete = async () => {
    if (!marketingTextId) return;

    await deleteMarketingText(marketingTextId);

    loadData();
    closeDeleteDialog();
  };
</script>

<PageLayout title={$_('routes.group.configurations.marketing-text')}>
  <svelte:fragment slot="criteria">
    <MarketingFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <Restricted to={Permission.MARKETING_WRITE}>
      <Button primary icon="plus" on:click={() => openSaveDialog()}>
        {$_('marketing-text.action.create.label')}
      </Button>
    </Restricted>
  </svelte:fragment>

  <svelte:fragment slot="content">
    <MarketingTable
      loading={$loading}
      resultsList={results}
      on:sort={handleSortChange}
      on:edit={openSaveDialog}
      on:delete={openDeleteDialog} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if showSaveDialog}
  <MarketingTextModalDialog
    title={marketingTextId
      ? $_('marketing-text.dialog.config.title.edit')
      : $_('marketing-text.dialog.config.title.create')}
    bind:show={showSaveDialog}
    initial={marketingTextData}
    on:save={handleSave}
    on:close={closeSaveDialog} />
{/if}

{#if showDeleteDialog && marketingTextName}
  <DeleteMarketingModalDialog
    bind:open={showDeleteDialog}
    title={$_('marketing-text.dialog.delete.title')}
    name={marketingTextName}
    on:confirm={handleConfirmDelete}
    on:cancel={closeDeleteDialog}>
    <svelte:fragment slot="warning">
      {$_('marketing-text.dialog.delete.warning')}
    </svelte:fragment>
    <svelte:fragment slot="confirm">
      {$_('marketing-text.dialog.delete.confirm')}
    </svelte:fragment>
  </DeleteMarketingModalDialog>
{/if}
