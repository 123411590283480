import { Permission as P } from 'src/user/permissions';
import { OrganizationRole, Role } from 'src/user/roles';
const ALLOWED_TO_ALL = [
    P.SCHEDULE_READ,
    P.AGENCY_READ,
    P.LINE_READ,
    P.STATION_READ,
    P.TRIP_READ,
    P.MESSAGE_READ,
    P.MARKETING_READ,
    P.LINE_CATEGORY_READ,
    P.LINE_COLOR_READ,
    P.LINE_TYPE_READ,
    P.SERVICE_POINT_READ,
    P.DISRUPTION_CAUSE_READ
];
const CONTENT_RW = [P.MARKETING_WRITE];
const NCA_PERMISSIONS = [P.ORGANIZATION_READ];
// Role mapping to permissions. Needs to be in sync with the backend, the same naming is used
// nca admin has access to all the permissions, no need to specify
export const ROLE_PERMISSION_MAPPING = {
    [OrganizationRole.NONE]: {
        [Role.NCA_ADMIN]: [],
        [Role.NCA_USER]: [...ALLOWED_TO_ALL, ...CONTENT_RW, ...NCA_PERMISSIONS],
        [Role.NCA_READONLY_USER]: [],
        [Role.OPERATOR_ADMIN]: [],
        [Role.OPERATOR_USER]: [],
        [Role.OPERATOR_READONLY_USER]: []
    },
    [OrganizationRole.DATA]: {
        [Role.NCA_ADMIN]: [],
        [Role.NCA_USER]: [...ALLOWED_TO_ALL, ...CONTENT_RW, ...NCA_PERMISSIONS],
        [Role.NCA_READONLY_USER]: [...ALLOWED_TO_ALL],
        [Role.OPERATOR_ADMIN]: [...ALLOWED_TO_ALL, ...CONTENT_RW],
        [Role.OPERATOR_USER]: [...ALLOWED_TO_ALL, ...CONTENT_RW],
        [Role.OPERATOR_READONLY_USER]: [...ALLOWED_TO_ALL]
    }
};
