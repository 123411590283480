/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { DisruptionCausesFromJSON, LineCategoriesFromJSON, LineColorsFromJSON, LineTypeMappingFromJSON, ServicePointsFromJSON, } from '../models/index';
/**
 *
 */
export class ConfigurationDataApi extends runtime.BaseAPI {
    /**
     * Get a page of disruption causes
     */
    findDisruptionCausesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['queryEq'] != null) {
                queryParameters['query.eq'] = requestParameters['queryEq'];
            }
            if (requestParameters['queryLike'] != null) {
                queryParameters['query.like'] = requestParameters['queryLike'];
            }
            if (requestParameters['causeEq'] != null) {
                queryParameters['cause.eq'] = requestParameters['causeEq'];
            }
            if (requestParameters['causeLike'] != null) {
                queryParameters['cause.like'] = requestParameters['causeLike'];
            }
            if (requestParameters['category'] != null) {
                queryParameters['category'] = requestParameters['category'];
            }
            if (requestParameters['group'] != null) {
                queryParameters['group'] = requestParameters['group'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/vdv-disruption-cause`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionCausesFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of disruption causes
     */
    findDisruptionCauses() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findDisruptionCausesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of line categories
     */
    findLineCategoriesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['transportTypeEq'] != null) {
                queryParameters['transportType.eq'] = requestParameters['transportTypeEq'];
            }
            if (requestParameters['transportTypeLike'] != null) {
                queryParameters['transportType.like'] = requestParameters['transportTypeLike'];
            }
            if (requestParameters['category'] != null) {
                queryParameters['category'] = requestParameters['category'];
            }
            if (requestParameters['transportGroup'] != null) {
                queryParameters['transportGroup'] = requestParameters['transportGroup'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line-category`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LineCategoriesFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of line categories
     */
    findLineCategories() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findLineCategoriesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a list of line color agencies
     */
    findLineColorAgenciesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/line-color/agency`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Get a list of line color agencies
     */
    findLineColorAgencies(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.findLineColorAgenciesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of line colors
     */
    findLineColorsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['lineNameEq'] != null) {
                queryParameters['lineName.eq'] = requestParameters['lineNameEq'];
            }
            if (requestParameters['lineNameLike'] != null) {
                queryParameters['lineName.like'] = requestParameters['lineNameLike'];
            }
            if (requestParameters['lineTypeEq'] != null) {
                queryParameters['lineType.eq'] = requestParameters['lineTypeEq'];
            }
            if (requestParameters['lineTypeLike'] != null) {
                queryParameters['lineType.like'] = requestParameters['lineTypeLike'];
            }
            if (requestParameters['agency'] != null) {
                queryParameters['agency'] = requestParameters['agency'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line-color`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LineColorsFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of line colors
     */
    findLineColors() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findLineColorsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get the line type mapping
     */
    findLineTypeMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line-type`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LineTypeMappingFromJSON(jsonValue));
        });
    }
    /**
     * Get the line type mapping
     */
    findLineTypeMapping() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findLineTypeMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of station data entries
     */
    findServicePointsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['countryCodeEq'] != null) {
                queryParameters['countryCode.eq'] = requestParameters['countryCodeEq'];
            }
            if (requestParameters['countryCodeLike'] != null) {
                queryParameters['countryCode.like'] = requestParameters['countryCodeLike'];
            }
            if (requestParameters['sloidEq'] != null) {
                queryParameters['sloid.eq'] = requestParameters['sloidEq'];
            }
            if (requestParameters['sloidLike'] != null) {
                queryParameters['sloid.like'] = requestParameters['sloidLike'];
            }
            if (requestParameters['nameEq'] != null) {
                queryParameters['name.eq'] = requestParameters['nameEq'];
            }
            if (requestParameters['nameLike'] != null) {
                queryParameters['name.like'] = requestParameters['nameLike'];
            }
            if (requestParameters['abbreviationEq'] != null) {
                queryParameters['abbreviation.eq'] = requestParameters['abbreviationEq'];
            }
            if (requestParameters['abbreviationLike'] != null) {
                queryParameters['abbreviation.like'] = requestParameters['abbreviationLike'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/service-point`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ServicePointsFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of station data entries
     */
    findServicePoints() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findServicePointsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
