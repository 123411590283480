<script lang="ts">
  import type { LineColor } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { Table, TableCol, type LoadingState, ColoredLine } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import { LineColorSort } from 'src/components/pages/lineColor/lineColorSort';

  export let resultsList: LineColor[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: LineColorSort };
  }>();

  const dispatchSortEvent = (sort: LineColorSort) => {
    dispatch('sort', { sort });
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('line-color.column.label.line-name')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineColorSort.LineName)}
    className="has-text-centered"
    width="10%">
    {#if row?.line?.name}
      <ColoredLine line={row.line.name} background={row.color.background} foreground={row.color.foreground} />
    {/if}
  </TableCol>

  <TableCol
    {row}
    header={$_('line-color.column.label.line-type')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineColorSort.LineType)}
    className="has-text-centered"
    width="10%">
    {#if row?.line?.type}
      <span
        style:--background-color={row.color.background}
        style:--foreground-color={row.color.foreground}
        class="line">
        {row.line.type}
      </span>
    {/if}
  </TableCol>

  <TableCol {row} value="comment" header={$_('line-color.column.label.comment')} />

  <TableCol
    {row}
    value="agency"
    header={$_('line-color.column.label.agency')}
    sortable
    onHeaderClick={() => dispatchSortEvent(LineColorSort.Agency)} />

  <TableCol {row} header={$_('line-color.column.label.color.background')}>
    {#if row?.color?.background}
      {row.color.background}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('line-color.column.label.color.foreground')}>
    {#if row?.color?.foreground}
      {row.color.foreground}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('line-color.column.label.color.border')}>
    {#if row?.color?.border}
      {row.color.border}
    {/if}
  </TableCol>
</Table>
