<script lang="ts">
  import type { MarketingTextFilterCriteria } from 'src/components/pages/marketing/marketingTextService';
  import { _ } from 'svelte-i18n';
  import { onDestroy } from 'svelte';
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import DateIntervalInput from 'src/components/shared/DateIntervalInput.svelte';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import OrganizationFilter from 'src/components/shared/OrganizationFilter.svelte';

  const abortController = new AbortController();
  export let filter: MarketingTextFilterCriteria;

  onDestroy(() => {
    abortController.abort();
  });

  const marketingTextValidityId = 'marketing-text-validity';
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('marketing-text.filter.name.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('marketing-text.filter.name.placeholder')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for={marketingTextValidityId}>
      {$_('marketing-text.filter.interval.label')}
    </label>
    <div class="control">
      <DateIntervalInput id={marketingTextValidityId} bind:fromDate={filter.fromDate} bind:toDate={filter.toDate}>
        {$_('marketing-text.filter.interval.until.label')}
      </DateIntervalInput>
    </div>
  </div>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <OrganizationFilter
      bind:selectedOrganizationId={filter.organizationId}
      emptyText={$_('marketing-text.filter.organization.no-items-text')}
      placeholder={$_('marketing-text.filter.organization.placeholder')}>
      {$_('marketing-text.filter.organization.label')}
    </OrganizationFilter>
  </Restricted>
</div>
