<script lang="ts">
  import DateInput from 'src/components/shared/DateInput.svelte';
  import { toHtmlId } from 'src/util/htmlUtils';

  export let id: string;

  export let fromDate: Date | undefined = undefined;
  export let toDate: Date | undefined = undefined;
  export let disabled = false;

  export let fromDateRequired = false;
  export let toDateRequired = false;

  const dateFromId = toHtmlId(id, 'interval-from');
  const dateToId = toHtmlId(id, 'interval-to');
</script>

<style>
  .interval-fields-group {
    display: flex;
    padding: 0 0.5rem;
    box-sizing: border-box;
    gap: 1.25rem;
    align-items: center;
    width: 100%;
  }

  .date-input-container {
    width: 100%;
  }
</style>

<div class="interval-fields-group p-0">
  <div class="date-input-container">
    <DateInput id={dateFromId} bind:value={fromDate} required={fromDateRequired} {disabled}>
      <slot name="from-label" />
    </DateInput>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; flex-shrink: 1;">
    <slot />
  </div>
  <div class="date-input-container">
    <DateInput id={dateToId} bind:value={toDate} required={toDateRequired} minDate={fromDate} {disabled}>
      <slot name="to-label" />
    </DateInput>
  </div>
</div>
