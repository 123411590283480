<script lang="ts" context="module">
  export type MarketingTextEdit = MarketingTextItem;
  export type MarketingTextDelete = MarketingTextItem;
</script>

<script lang="ts">
  import type { MarketingTextItem } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { MarketingTextSort } from 'src/components/pages/marketing/marketingTextSort';
  import { ActionIcon, Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';
  import { DATE_FORMAT } from 'src/util/dateTimeUtils';
  import { format } from 'date-fns';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';

  export let resultsList: MarketingTextItem[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: MarketingTextSort };
    edit: MarketingTextEdit;
    delete: MarketingTextDelete;
  }>();

  const dispatchSortEvent = (sort: MarketingTextSort) => {
    dispatch('sort', { sort });
  };

  const dispatchEdit = (data: MarketingTextItem) => {
    dispatch('edit', data);
  };

  const dispatchDelete = (data: MarketingTextItem) => {
    dispatch('delete', data);
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('marketing-text.column.name.header-label')}
    value="name"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.Name)} />
  <TableCol
    {row}
    header={$_('marketing-text.column.date.start.header-label')}
    value="startDate"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.StartDate)}>
    {#if row?.startDate}
      {format(row.startDate, DATE_FORMAT)}
    {/if}
  </TableCol>
  <TableCol
    {row}
    header={$_('marketing-text.column.date.end.header-label')}
    value="endDate"
    sortable
    onHeaderClick={() => dispatchSortEvent(MarketingTextSort.EndDate)}>
    {#if row?.endDate}
      {format(row.endDate, DATE_FORMAT)}
    {/if}
  </TableCol>
  <Restricted to={Permission.MARKETING_WRITE}>
    <TableCol {row} header={$_('marketing-text.column.actions.header-label')}>
      {#if row}
        <ActionIcon icon="edit_note" on:click={() => dispatchEdit(row)} />
        <ActionIcon icon="delete-outline" on:click={() => dispatchDelete(row)} />
      {/if}
    </TableCol>
  </Restricted>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <TableCol {row} header={$_('marketing-text.column.organization.header-label')} value="organization">
      {#if row?.organization.name}
        {row.organization.name}
      {/if}
    </TableCol>
  </Restricted>
</Table>
