/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationFromJSON, OrganizationToJSON, } from './Organization';
/**
 * Check if a given object implements the OrganizationResponse interface.
 */
export function instanceOfOrganizationResponse(value) {
    if (!('organizations' in value) || value['organizations'] === undefined)
        return false;
    return true;
}
export function OrganizationResponseFromJSON(json) {
    return OrganizationResponseFromJSONTyped(json, false);
}
export function OrganizationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'organizations': (json['organizations'].map(OrganizationFromJSON)),
    };
}
export function OrganizationResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'organizations': (value['organizations'].map(OrganizationToJSON)),
    };
}
