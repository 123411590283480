import { __awaiter } from "tslib";
import { HubCacheApiError } from 'src/api/httpErrorHandling';
import { getTranslatedDefaultErrorText } from 'src/api/notification';
export class AbortReason {
    constructor(result) {
        Object.defineProperty(this, "result", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.result = result;
    }
}
/**
 * Creates a successful fetch result with the given data and optionally pagination.
 *
 * @param results the actual data
 * @param pagination the pagination details
 */
export const createFetchResult = (results, pagination) => {
    return {
        results,
        pagination,
        isError: false
    };
};
/**
 * Wraps the data received from the given provider into a FetchResult.
 */
export const wrap = (dataProvider) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield dataProvider();
        return createFetchResult(result);
    }
    catch (e) {
        if (e instanceof AbortReason) {
            return e.result;
        }
        return handleError(e);
    }
});
/**
 * Wraps the data received from the given provider into a FetchResult with pagination.
 *
 * @param dataProvider the data provider
 * @param dataResolver function to resolve the actual data (FetchResult.results)
 * @param paginationResolver function to resolve the actual pagination (FetchResult.pagination)
 */
export const wrapPaginated = (dataProvider_1, ...args_1) => __awaiter(void 0, [dataProvider_1, ...args_1], void 0, function* (dataProvider, dataResolver = defaultDataResolver, paginationResolver = defaultPaginationResolver) {
    try {
        const result = yield dataProvider();
        const data = dataResolver(result);
        const pagination = paginationResolver(result);
        return createFetchResult(data, pagination);
    }
    catch (e) {
        if (e instanceof AbortReason) {
            return e.result;
        }
        return handleError(e);
    }
});
const handleError = (e) => {
    const error = getHubError(e);
    return createErrorFetchResult(error.code, error.message, error.details);
};
const defaultDataResolver = (response) => response.results;
const defaultPaginationResolver = (response) => { var _a; return (_a = response === null || response === void 0 ? void 0 : response.metadata) === null || _a === void 0 ? void 0 : _a.pagination; };
/**
 * Extract the error code & message from the given error.
 *
 * @param e the thrown error
 */
export function getHubError(e) {
    if (e instanceof HubCacheApiError) {
        return {
            code: e.code,
            message: e.message,
            details: e.details
        };
    }
    return {
        code: 'UNKNOWN',
        message: getTranslatedDefaultErrorText()
    };
}
/**
 * Creates a non-successful fetch result.
 *
 * @param code the error code
 * @param message the message describing the error
 * @param details more information regarding the error, optional
 */
export const createErrorFetchResult = (code, message, details) => {
    return {
        isError: true,
        error: {
            code: code !== null && code !== void 0 ? code : 'UNKNOWN',
            message: message !== null && message !== void 0 ? message : getTranslatedDefaultErrorText(),
            details
        }
    };
};
