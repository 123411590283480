<script lang="ts">
  import { link } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import { _ } from 'svelte-i18n';
  import type { Condition, MenuGroup } from '@pids/shared-component';
  import { Icon, SideNavigationLayout } from '@pids/shared-component';
  import { hasAnyPermission } from 'src/user/permissionCheck';
  import { Permission } from 'src/user/permissions';
  import { user } from 'src/user/userStore';
  import { shouldShowVdvDisruptionCausePage } from 'src/components/pages/routesConditions';
  import { routeLinks } from 'src/components/pages/routingService';

  const requiresPermission = (permissions: Permission[]): Condition => {
    return () => hasAnyPermission($user, permissions);
  };

  const groups: MenuGroup[] = [
    {
      groupName: $_('routes.group.core'),
      condition: requiresPermission([Permission.SCHEDULE_READ]),
      items: [
        {
          label: $_('routes.group.core.schedules'),
          icon: 'departure_board',
          href: routeLinks.schedule,
          matchPath: `${routeLinks.schedule}.*`
        },
        {
          label: $_('routes.group.core.agency'),
          icon: 'business',
          href: routeLinks.agency,
          matchPath: `${routeLinks.agency}.*`
        },
        {
          label: $_('routes.group.core.line'),
          icon: 'looks_3',
          href: routeLinks.line,
          matchPath: `${routeLinks.line}.*`
        },
        {
          label: $_('routes.group.core.station'),
          icon: 'location',
          href: routeLinks.station,
          matchPath: `${routeLinks.station}.*`
        },
        {
          label: $_('routes.group.core.trip'),
          icon: 'route',
          href: routeLinks.trip,
          matchPath: `${routeLinks.trip}.*`
        },
        {
          label: $_('routes.group.core.trip-by-station'),
          icon: 'pin_route',
          href: routeLinks.tripsByStation,
          matchPath: `${routeLinks.tripsByStation}.*`
        },
        {
          label: $_('routes.group.core.message'),
          icon: 'info-message',
          href: routeLinks.message,
          matchPath: `${routeLinks.message}.*`
        }
      ]
    },
    {
      groupName: $_('routes.group.configurations'),
      condition: requiresPermission([Permission.MARKETING_READ]),
      items: [
        {
          label: $_('routes.group.configurations.service-point'),
          href: routeLinks.servicePoint,
          matchPath: `${routeLinks.servicePoint}.*`
        },
        {
          label: $_('routes.group.configurations.line-type-category-mapping'),
          href: routeLinks.lineCategory,
          matchPath: `${routeLinks.lineCategory}.*`
        },
        {
          label: $_('routes.group.configurations.line-color'),
          href: routeLinks.lineColor,
          matchPath: `${routeLinks.lineColor}.*`
        },
        {
          label: $_('line-type.page-title'),
          href: routeLinks.lineType,
          matchPath: `${routeLinks.lineType}.*`
        },
        {
          label: $_('routes.group.configurations.marketing-text'),
          href: routeLinks.marketing,
          matchPath: `${routeLinks.marketing}.*`
        },
        {
          label: $_('routes.group.configurations.disruption-causes'),
          href: routeLinks.vdvDisruptionCause,
          matchPath: `${routeLinks.vdvDisruptionCause}.*`,
          condition: () => shouldShowVdvDisruptionCausePage()
        }
      ]
    },
    {
      groupName: $_('routes.group.admin'),
      condition: requiresPermission([Permission.FEED_MANAGEMENT]),
      items: [
        {
          label: $_('routes.group.admin.feed-management'),
          href: routeLinks.feedManagement,
          matchPath: `${routeLinks.feedManagement}.*`
        }
      ]
    }
  ];
</script>

<style>
  .link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>

<SideNavigationLayout {groups} let:item>
  <a href={item.href} use:link class="link" use:active={{ path: item.matchPath, className: 'is-active' }}>
    {#if item.icon}
      <span class="icon">
        <Icon name={item.icon} style="font-size: 1.25rem;" />
      </span>
    {/if}
    <span>{item.label}</span>
  </a>
</SideNavigationLayout>
