<script lang="ts">
  import DateIntervalInput from 'src/components/shared/DateIntervalInput.svelte';
  import { toHtmlId } from 'src/util/htmlUtils';

  export let id: string;

  export let fromDate: Date | undefined = undefined;
  export let toDate: Date | undefined = undefined;

  export let fromDateRequired = false;
  export let toDateRequired = false;

  let indefinite: boolean = !Boolean(fromDate) && !Boolean(toDate);
  let fromDateValue: Date | undefined = fromDate;
  let toDateValue: Date | undefined = toDate;

  const indefiniteId = toHtmlId(id, 'interval-indefinite');
  const dateIntervalId = toHtmlId(id, 'interval');

  const handleValueChange = (value: boolean) => {
    if (value) {
      fromDate = undefined;
      toDate = undefined;
    } else {
      fromDate = fromDateValue;
      toDate = toDateValue;
    }
  };

  $: handleValueChange(indefinite);
  $: fromDate = fromDateValue;
  $: toDate = toDateValue;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .dt-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    user-select: none;
  }

  label.disabled {
    color: $medium-emphasis;
  }

  .interval-controls {
    display: flex;
    gap: 0.5rem;
  }

  .radio-field label {
    padding-left: 2rem;
  }
</style>

<div class="dt-radio-group">
  <div class="field m-0 radio-field">
    <input type="radio" class="is-checkradio" id={indefiniteId} bind:group={indefinite} value={true} />
    <label class="label" class:disabled={!indefinite} for={indefiniteId}>
      <slot name="indefinite-label" />
    </label>
  </div>

  <div class="field m-0 radio-field">
    <input type="radio" class="is-checkradio" id={dateIntervalId} bind:group={indefinite} value={false} />
    <label class="label interval-controls" for={dateIntervalId}>
      <DateIntervalInput
        id={dateIntervalId}
        bind:fromDate={fromDateValue}
        bind:toDate={toDateValue}
        {fromDateRequired}
        {toDateRequired}
        disabled={indefinite}>
        <slot />
        <slot name="from-label" slot="from-label" />
        <slot name="to-label" slot="to-label" />
      </DateIntervalInput>
    </label>
  </div>
</div>
