<script lang="ts">
  import { toHtmlId } from 'src/util/htmlUtils';
  import { ClearIcon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import { format, parse } from 'date-fns';

  const ISO_DATE_FORMAT = 'yyyy-MM-dd';

  export let id: string;
  export let value: Date | undefined;
  export let required = false;
  export let minDate: Date | undefined = undefined;
  export let maxDate: Date | undefined = undefined;
  export let disabled = false;

  let interacted = false;
  let dateValue = value && format(value, ISO_DATE_FORMAT);

  const inputId = toHtmlId(id, 'date-input');

  const handleClear = () => {
    dateValue = undefined;
  };

  $: value = dateValue ? parse(dateValue, ISO_DATE_FORMAT, new Date()) : undefined;
  $: showClear = value !== undefined;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  label.disabled {
    color: $medium-emphasis;
  }

  label.invalid {
    color: $alert-error !important;
  }

  input.invalid {
    outline-color: $alert-error !important;
    outline: 0.0625rem solid;
    outline-offset: 0.06rem;
  }

  .field-date input {
    display: inline-block;
  }

  .date-field-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .clear-icon {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  button {
    border: none;
    padding: 0;
    text-align: left;
    font-size: inherit;
    background-color: transparent;
    width: auto;
    cursor: pointer;
    pointer-events: all;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.35rem;
    top: 0;
  }

  input.clearable {
    padding-right: 1.75rem;
  }

  .field-date.disabled * {
    pointer-events: none;
  }
</style>

<div class="field-date" class:disabled>
  <label for={inputId} class="has-text-grey date-field-label">
    <slot />
  </label>
  <div class="control" style="position: relative;">
    <input
      id={inputId}
      class="input"
      class:invalid={interacted && required && (!value || (minDate && value < minDate))}
      class:clearable={showClear}
      class:disabled
      bind:value={dateValue}
      min={minDate && format(minDate, ISO_DATE_FORMAT)}
      max={maxDate && format(maxDate, ISO_DATE_FORMAT)}
      {disabled}
      type="date"
      on:blur={() => (interacted = true)} />
    {#if showClear}
      <button type="button" class="clear-icon" on:click={handleClear} {disabled}>
        <ClearIcon />
      </button>
    {/if}
  </div>
</div>
