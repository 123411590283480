<script lang="ts">
  import { ModalDialog } from '@pids/shared-component';
  import MarketingTextForm from 'src/components/pages/marketing/MarketingTextForm.svelte';
  import type { CreateEditMarketingText } from 'src/generated/service/cache-api';
  import { isNotBlank } from 'src/util/stringUtils';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';

  const dispatch = createEventDispatcher<{
    save: CreateEditMarketingText;
    close: void;
  }>();

  export let show = false;
  export let title: string = '';
  export let initial: CreateEditMarketingText | undefined = undefined;

  let data: Partial<CreateEditMarketingText>;

  const handleSave = () => {
    dispatch('save', data as CreateEditMarketingText);
  };

  const handleClose = () => {
    dispatch('close');
  };

  const validate = (data: Partial<CreateEditMarketingText>) => {
    return Boolean(
      data &&
        isNotBlank(data.name) &&
        isNotBlank(data.textEn) &&
        isNotBlank(data.textDe) &&
        isNotBlank(data.textFr) &&
        isNotBlank(data.textIt) &&
        (!data.startDate || !data.endDate || (data.endDate && data.startDate && data.endDate >= data.startDate)) &&
        data.trains &&
        data.trains.length > 0
    );
  };

  $: isValid = validate(data);
</script>

{#if show}
  <ModalDialog
    {title}
    bind:active={show}
    okText={$_('button.ok')}
    cancelText={$_('button.cancel')}
    overflow="visible"
    showCancel={true}
    okHandler={handleSave}
    cancelHandler={handleClose}
    primaryButtonEnabled={isValid}
    width={1080}>
    <MarketingTextForm {initial} bind:data />
  </ModalDialog>
{/if}
