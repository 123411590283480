/**
 * Combines the given keywords to an HTML id.
 * <br>
 * Any whitespace is converted to a hyphen (kebab-case).
 * <br>
 * And the final id is cleaned by having any prefix or
 * duplicate hyphens handled.
 * @param parts
 */
export const toHtmlId = (...parts) => {
    return parts
        .map(p => p.replaceAll(/\s+/g, '-'))
        .join('-')
        .replace(/-+/g, '-')
        .replace(/^-/, '');
};
