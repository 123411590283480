<script lang="ts">
  import MessageInput from 'src/components/pages/marketing/MessageInput.svelte';
  import TrainNumberInput from 'src/components/pages/marketing/TrainNumberInput.svelte';
  import ValidityInput from 'src/components/pages/marketing/ValidityInput.svelte';
  import TextInput from 'src/components/shared/TextInput.svelte';
  import type { CreateEditMarketingText } from 'src/generated/service/cache-api';
  import { deepCopy } from 'src/util/objectUtils';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  export let initial: CreateEditMarketingText | undefined = undefined;
  export let data: Partial<CreateEditMarketingText>;

  onMount(() => {
    data = initial ? deepCopyMarketingText(initial) : {};
  });

  const deepCopyMarketingText = (value: CreateEditMarketingText): Partial<CreateEditMarketingText> => {
    const copy = deepCopy(value);
    copy.startDate = value.startDate;
    copy.endDate = value.endDate;
    return copy;
  };
</script>

<style>
  .form-container {
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    gap: 2rem;
  }

  .form-side {
    display: grid;
    gap: 2rem;
  }
</style>

{#if data}
  <div class="form-container">
    <div class="form-side">
      <TextInput id="MarketingTextName" bind:value={data.name} required>
        {$_('marketing-text.dialog.config.name.label')}
      </TextInput>
      <MessageInput bind:data />
      <ValidityInput bind:value={data} />
    </div>
    <div class="form-side">
      <TrainNumberInput bind:trains={data.trains} id="marketing-text-train-number" required />
    </div>
  </div>
{/if}
