<script lang="ts">
  import { DropdownSelect } from '@pids/shared-component';
  import type { Organization } from 'src/generated/service/cache-api';
  import { getAllOrganizations } from 'src/services/organizationService';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  export let selectedOrganizationId: number | undefined = undefined;

  export let placeholder: string = '';
  export let emptyText: string = '';

  let organizations: Organization[];
  let selectedOrganization: Organization | undefined;

  const organizationFilter = (name: string) => {
    return organizations.filter(o => o.name.includes(name));
  };

  onMount(async () => {
    organizations = await getAllOrganizations();
  });

  $: selectedOrganizationId = selectedOrganization?.id;
</script>

<div class="column">
  {#if organizations}
    <label for="organizatoin-filter">
      <slot />
    </label>
    <div class="control">
      <DropdownSelect
        id="organizatoin-filter"
        items={organizations}
        bind:value={selectedOrganization}
        filter={organizationFilter}
        {placeholder}
        empty={emptyText}
        readonly={Boolean(selectedOrganization)}
        let:item>
        {item.name}
      </DropdownSelect>
    </div>
  {/if}
</div>
