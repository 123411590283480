<script lang="ts">
  import TextInput from 'src/components/shared/TextInput.svelte';
  import { type CreateEditMarketingText } from 'src/generated/service/cache-api';
  import { _ } from 'svelte-i18n';

  export let data: Partial<CreateEditMarketingText>;
</script>

<div>
  <p class="menu-label is-size-7 has-text-weight-semibold has-text-black mb-2">
    {$_('marketing-text.dialog.config.message')}
  </p>
  <TextInput id="MarketinDe" bind:value={data.textDe} required>
    {$_('marketing-text.dialog.config.message.de')}
  </TextInput>
  <TextInput id="MarketinFr" bind:value={data.textFr} required>
    {$_('marketing-text.dialog.config.message.fr')}
  </TextInput>
  <TextInput id="MarketinIt" bind:value={data.textIt} required>
    {$_('marketing-text.dialog.config.message.it')}
  </TextInput>
  <TextInput id="MarketinEn" bind:value={data.textEn} required>
    {$_('marketing-text.dialog.config.message.en')}
  </TextInput>
</div>
