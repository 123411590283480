<script lang="ts">
  import type { Line, Trip } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Button, Table, TableCol, LineIcon, getLineIcon, ColoredLine } from '@pids/shared-component';
  import { ActionIcon, type LoadingState } from '@pids/shared-component';
  import { TripSort } from './types';
  import { quoted } from 'src/util/stringUtils';
  import { toLinesLink, toTripDetailsLink } from 'src/components/pages/routingService';
  import { fromTripsPage } from 'src/components/pages/trips/tripService';

  export let results: Trip[];
  export let loading: LoadingState = undefined;
  export let stationId: string | undefined = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: TripSort };
  }>();

  const sortByCol = (col: TripSort) => {
    return () => dispatch('sort', { sort: col });
  };

  const getLineLink = (line: Line) => {
    return toLinesLink({
      id: quoted(line.id),
      schedule: line.schedule
    });
  };

  const navigateToTripDetails = (): void => {
    $fromTripsPage = true;
  };

  const toLink = (trip: Trip | undefined) => {
    if (!trip) {
      return '#';
    }

    return toTripDetailsLink({
      id: trip.id,
      schedule: trip.schedule,
      stationId
    });
  };
</script>

<style>
  .canceled {
    text-decoration: line-through;
  }
</style>

<Table rows={results} {loading} let:row emptyText={$_('table.empty.default')} loadingText={$_('table.loading.default')}>
  <TableCol {row} header={$_('trip.column.line.label')} width="6%" sortable onHeaderClick={sortByCol(TripSort.Line)}>
    {@const line = row?.line}
    {#if line}
      <Button link={true} compact={true} href={getLineLink(line)}>
        <LineIcon height="1.25rem" type={getLineIcon(line.type)} number={line.number ?? ''}>
          {#if line.name}
            <ColoredLine line={line.name} background={line.color?.background} foreground={line.color?.foreground} />
          {/if}
        </LineIcon>
      </Button>
    {/if}
  </TableCol>

  <TableCol
    {row}
    header={$_('trip.column.destination.label')}
    width="25%"
    sortable
    onHeaderClick={sortByCol(TripSort.Destination)}>
    {#if row && row.destinations}
      <p>{row.destinations.join(' / ')}</p>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('trip.column.destination.short.label')} value="shortDestination" width="15%" />

  <TableCol
    {row}
    header={$_('trip.column.start-time.label')}
    width="8%"
    sortable
    onHeaderClick={sortByCol(TripSort.StartTime)}>
    {#if row?.startTime}
      <span class:canceled={row.canceled}>{row.nextDay ? `+${String(row.startTime)}` : row.startTime}</span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('trip.column.agency.label')} width="10%">
    {row?.line?.agency ?? ''}
  </TableCol>

  <TableCol {row} value="id" header={$_('trip.column.id.label')} width="15%">
    {#if row?.id}
      <span class:canceled={row.canceled}>{row.id}</span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('trip.column.schedule.label')} width="21%">
    {#if row?.schedule}
      {row?.schedule}
    {/if}
  </TableCol>

  <TableCol {row}>
    <ActionIcon href={toLink(row)} icon="search" on:click={() => navigateToTripDetails()} />
  </TableCol>
</Table>
