<script lang="ts">
  import DateInput from 'src/components/shared/DateInput.svelte';
  import { toHtmlId } from 'src/util/htmlUtils';
  import { _ } from 'svelte-i18n';

  export let id: string;

  export let fromDate: Date | undefined = undefined;
  export let toDate: Date | undefined = undefined;

  export let fromDateRequired = false;
  export let toDateRequired = false;

  let startEnabled = Boolean(fromDate);
  let endEnabled = Boolean(toDate);
  let fromDateValue: Date | undefined = fromDate;
  let toDateValue: Date | undefined = toDate;

  const dateIntervalFromId = toHtmlId(id, 'interval-from');
  const dateIntervalToId = toHtmlId(id, 'interval-to');
  const dateIntervalFromToggleId = toHtmlId(id, 'interval-from-toggle');
  const dateIntervalToToggleId = toHtmlId(id, 'interval-to-toggle');

  const handleFromEnableToggle = (startEnabled: boolean) => {
    if (startEnabled) {
      fromDate = fromDateValue;
    } else {
      fromDate = undefined;
    }
  };

  const handleToEnableToggle = (startEnabled: boolean) => {
    if (startEnabled) {
      toDate = toDateValue;
    } else {
      toDate = undefined;
    }
  };

  $: handleFromEnableToggle(startEnabled);
  $: handleToEnableToggle(endEnabled);

  $: fromDate = fromDateValue;
  $: toDate = toDateValue;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .dt-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    user-select: none;
  }

  label.disabled {
    color: $medium-emphasis;
  }

  .interval-controls {
    display: flex;
    gap: 0.5rem;
  }

  .radio-field label {
    padding-left: 2rem;
  }

  .date-container {
    display: flex;
    justify-content: space-between;
  }

  .date-input-container {
    max-width: 20rem;
    flex-grow: 1;
  }
</style>

<div class="is-flex is-flex-direction-column" style="gap: 2rem;">
  <div class="date-container" style="gap: 2rem;">
    <input type="checkbox" bind:checked={startEnabled} class="is-checkradio" id={dateIntervalFromToggleId} />
    <label
      for={dateIntervalFromToggleId}
      style:display="flex"
      style:align-items="center"
      class="label checkbox-label-custom mb-0">
      <slot name="from-label" />
    </label>
    <div class="date-input-container">
      <DateInput
        id={dateIntervalFromId}
        bind:value={fromDateValue}
        maxDate={toDate}
        required={fromDateRequired}
        disabled={!startEnabled} />
    </div>
  </div>

  <div class="date-container" style="gap: 2rem;">
    <input type="checkbox" bind:checked={endEnabled} class="is-checkradio" id={dateIntervalToToggleId} />
    <label
      for={dateIntervalToToggleId}
      style:display="flex"
      style:align-items="center"
      class="label checkbox-label-custom mb-0">
      <slot name="to-label" />
    </label>
    <div class="date-input-container">
      <DateInput
        id={dateIntervalToId}
        bind:value={toDateValue}
        required={toDateRequired}
        minDate={fromDate}
        disabled={!endEnabled} />
    </div>
  </div>
</div>
