/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { CreateEditMarketingTextToJSON, MarketingTextFromJSON, MarketingTextOverviewFromJSON, } from '../models/index';
/**
 *
 */
export class MarketingApi extends runtime.BaseAPI {
    /**
     */
    createMarketingTextRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createEditMarketingText'] == null) {
                throw new runtime.RequiredError('createEditMarketingText', 'Required parameter "createEditMarketingText" was null or undefined when calling createMarketingText().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/marketing`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditMarketingTextToJSON(requestParameters['createEditMarketingText']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MarketingTextFromJSON(jsonValue));
        });
    }
    /**
     */
    createMarketingText(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createMarketingTextRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteMarketingTextRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteMarketingText().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/marketing/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteMarketingText(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteMarketingTextRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    getAllMarketingTextsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['nameLike'] != null) {
                queryParameters['name.like'] = requestParameters['nameLike'];
            }
            if (requestParameters['nameEq'] != null) {
                queryParameters['name.eq'] = requestParameters['nameEq'];
            }
            if (requestParameters['startDate'] != null) {
                queryParameters['startDate'] = requestParameters['startDate'].toISOString().substring(0, 10);
            }
            if (requestParameters['endDate'] != null) {
                queryParameters['endDate'] = requestParameters['endDate'].toISOString().substring(0, 10);
            }
            if (requestParameters['organizationId'] != null) {
                queryParameters['organizationId'] = requestParameters['organizationId'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'].join(runtime.COLLECTION_FORMATS["csv"]);
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/marketing`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MarketingTextOverviewFromJSON(jsonValue));
        });
    }
    /**
     */
    getAllMarketingTexts() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getAllMarketingTextsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getMarketingTextRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getMarketingText().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/marketing/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MarketingTextFromJSON(jsonValue));
        });
    }
    /**
     */
    getMarketingText(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMarketingTextRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    updateMarketingTextRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateMarketingText().');
            }
            if (requestParameters['createEditMarketingText'] == null) {
                throw new runtime.RequiredError('createEditMarketingText', 'Required parameter "createEditMarketingText" was null or undefined when calling updateMarketingText().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/marketing/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditMarketingTextToJSON(requestParameters['createEditMarketingText']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MarketingTextFromJSON(jsonValue));
        });
    }
    /**
     */
    updateMarketingText(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateMarketingTextRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
