import { notificationService } from '@pids/shared-component';
import { getTranslatedDefaultErrorText } from 'src/api/notification';
import { getHubError } from 'src/api/query';
export const errorHandlerWithDefault = (defaultValue) => {
    return (error) => {
        return handleError(error, defaultValue);
    };
};
export const silentErrorHandlerWithDefault = (defaultValue) => {
    return (error) => {
        return handlerErrorSilently(error, defaultValue);
    };
};
export const handleError = (e, defaultValue) => {
    var _a;
    console.error(e);
    const error = getHubError(e);
    notificationService.error((_a = error.message) !== null && _a !== void 0 ? _a : getTranslatedDefaultErrorText());
    return defaultValue;
};
export const handlerErrorSilently = (e, defaultValue) => {
    console.error(e);
    return defaultValue;
};
