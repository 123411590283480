<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Icon, PageLayout, ColoredLine } from '@pids/shared-component';
  import type { AffectedStation, DisruptionMessage } from '$generated/service/cache-api';
  import type { FetchResult } from 'src/api/query';
  import { selectedMessage } from './messageStore';
  import TextRow from './TextRow.svelte';
  import { formatInterval } from './messageUtils';
  import { fetchMessage } from './messageService';
  import { querystring } from 'svelte-spa-router';
  import { messageDetailsParamNames } from 'src/components/pages/message/types';

  $: queryParams = new URLSearchParams($querystring);
  $: id = queryParams.get(messageDetailsParamNames.id);
  $: scheduleId = queryParams.get(messageDetailsParamNames.schedule);

  let message: DisruptionMessage | undefined;

  onMount(() => {
    message = $selectedMessage;

    if (!message) {
      fetchMessageDetails();
    } else if (message.id !== id || message.schedule !== scheduleId) {
      throw Error('The provided message does not match the url params');
    }
  });

  const fetchMessageDetails = async () => {
    if (!id || !scheduleId) {
      return;
    }

    const response: FetchResult<DisruptionMessage> = await fetchMessage(id, scheduleId);
    message = response.isError ? undefined : response.results;
  };

  const formatStationName = (station: AffectedStation): string => {
    return `${station.name} (` + (station.shortName ? `${station.shortName}, ` : '') + `${station.id})`;
  };

  $: queryParams, fetchMessageDetails();
</script>

<style>
  #message-details-tbl tr td:first-child {
    font-weight: 600;
  }

  .h-list li {
    display: inline-block;
    margin-right: 0.5rem;
  }
</style>

{#if message}
  <PageLayout title={$_('message.details.title')}>
    <svelte:fragment slot="content">
      <table id="message-details-tbl" class="table is-hoverable is-fullwidth">
        <colgroup>
          <col span="1" style="width: 15%" />
          <col span="1" style="width: 85%" />
        </colgroup>
        <tbody>
          <tr>
            <td>{$_('message.column.id.label')}</td>
            <td>{message.id}</td>
          </tr>
          <tr>
            <td>{$_('message.column.intervals.label')}</td>
            <td>
              {#if message.activeIntervals}
                {#each message.activeIntervals as interval}
                  <p>{formatInterval(interval)}</p>
                {/each}
              {/if}
            </td>
          </tr>
          <tr>
            <td>{$_('message.column.cause.label')}</td>
            <td>{message.cause}</td>
          </tr>
          <tr>
            <td>{$_('message.column.schedule.label')}</td>
            <td>{message.schedule}</td>
          </tr>
          {#if message.stations && message.stations.length > 0}
            <tr>
              <td>{$_('message.column.stations.label')}</td>
              <td>
                <ul>
                  {#each message.stations as station}
                    <li>
                      <span>
                        <Icon name="location" />
                        {formatStationName(station)}
                      </span>
                    </li>
                  {/each}
                </ul>
              </td>
            </tr>
          {/if}
          {#if message.lines && message.lines.length > 0}
            <tr>
              <td>{$_('message.column.lines.label')}</td>
              <td>
                <ul class="h-list">
                  {#each message.lines as line}
                    <li>
                      {#if line.name}
                        <ColoredLine
                          line={line.name}
                          background={line.color?.background}
                          foreground={line.color?.foreground} />
                      {:else}
                        <p>{line.id}</p>
                      {/if}
                    </li>
                  {/each}
                </ul>
              </td>
            </tr>
          {/if}
          <TextRow label={$_('message.column.text.label')} texts={message.texts} />
        </tbody>
      </table>
    </svelte:fragment>
  </PageLayout>
{/if}
