<script lang="ts">
  import { Button, defaultStringStartsWithFilter, DropdownSelect } from '@pids/shared-component';
  import { ScheduleTypeEnum, type TrainNumber } from 'src/generated/service/cache-api';
  import { toHtmlId } from 'src/util/htmlUtils';
  import { _ } from 'svelte-i18n';
  import TextInput from 'src/components/shared/TextInput.svelte';
  import type { SvelteEvent } from 'src/model/event';
  import { fetchAgencies } from 'src/components/pages/agency/agencyService';
  import { fetchSchedules } from 'src/components/pages/schedule/scheduleService';
  import { AgencySort } from 'src/components/pages/agency/agencySort';
  import { onMount } from 'svelte';
  import { isToday } from 'date-fns';

  export let id: string;
  export let trains: TrainNumber[] = [];
  export let required = false;

  let isValid = true;
  let trainNumber: Partial<TrainNumber> = {
    agency: undefined,
    number: undefined
  };

  let interacted = false;
  let agencies: string[] = [];

  const trainAgencyId = toHtmlId(id, 'agency');
  const trainNumberValueId = toHtmlId(id, 'value');

  const addTrainNumber = () => {
    if (!isValid) return;

    trains = [...(trains ?? []), trainNumber as TrainNumber].filter(
      (train, index, self) => index === self.findIndex(t => equals(t, train))
    );
    trainNumber = {
      agency: undefined,
      number: undefined
    };
    interacted = true;
  };

  const handleCreate = ({ detail: newAgency }: SvelteEvent<string>) => {
    trainNumber.agency = newAgency;
  };

  const getSchedules = async () => {
    const schedules = await fetchSchedules();
    if (schedules.isError) return;
    return schedules.results;
  };

  const requestAgencies = async (searchText: string = '') => {
    const schedules = await getSchedules();
    if (!schedules?.length) return [];
    const schedule = schedules.find(
      s => s.type === ScheduleTypeEnum.DailySchedule && isToday(s.validity.start) && isToday(s.validity.end)
    );

    const response = await fetchAgencies(
      { name: searchText, schedule: schedule ? { id: schedule.id } : undefined },
      { page: 1, size: 100, sort: `${AgencySort.Name},asc` }
    );
    if (response.isError) return [];

    agencies = [...new Set(response.results.map(s => s.name))];

    return agencies;
  };

  const equals = (left: TrainNumber, right: TrainNumber): boolean =>
    left.agency === right.agency && left.number === right.number;

  onMount(() => {
    requestAgencies();
  });

  $: isValid = Boolean(trainNumber.agency) && Boolean(trainNumber.number);
</script>

<style>
  .field-container {
    display: flex;
    gap: 1rem;
  }

  .field-container .input-field,
  .field-container {
    width: 100%;
  }
</style>

<div>
  <p class="menu-label is-size-7 has-text-weight-semibold has-text-black mb-2">
    {$_('marketing-text.dialog.config.train')}
  </p>

  <div class="field-container mb-3">
    <div class="field-container">
      <div class="input-field">
        <div class="field-text">
          <label for={trainAgencyId} class="has-text-grey text-field-label">
            {$_('marketing-text.dialog.config.train.agency')}
          </label>
          <div class="control" style="position: relative;">
            <DropdownSelect
              id={trainAgencyId}
              bind:value={trainNumber.agency}
              items={agencies}
              creatable
              filter={defaultStringStartsWithFilter}
              placeholder={$_('marketing-text.dialog.config.train.agency.placeholder')}
              on:create={handleCreate}>
            </DropdownSelect>
          </div>
        </div>
      </div>
      <div class="input-field">
        <TextInput id={trainNumberValueId} bind:value={trainNumber.number}>
          {$_('marketing-text.dialog.config.train.number')}
        </TextInput>
      </div>
      <div>
        <div class="is-small has-text-grey" aria-hidden="true">&nbsp;</div>
        <div class="control">
          <Button primary={true} icon="plus" on:click={addTrainNumber} disabled={!isValid}>
            {$_('button.add')}
          </Button>
        </div>
      </div>
    </div>
  </div>

  <DropdownSelect
    bind:values={trains}
    multiple={true}
    readonly={true}
    enableList={false}
    hasError={interacted && required && (!trains || trains.length <= 0)}
    on:focus={() => (interacted = true)}
    placeholder={$_('marketing-text.dialog.config.train.placeholder')}
    {equals}
    let:item>
    {`${item.agency}:${item.number}`}
  </DropdownSelect>
</div>
