import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
export const DEFAULT_SUCCESS_TEXT_KEY = 'notification.common.success';
export const DEFAULT_ERROR_TEXT_KEY = 'notification.common.error';
export const getTranslatedDefaultSuccessText = () => {
    return get(_)(DEFAULT_ERROR_TEXT_KEY);
};
export const getTranslatedDefaultErrorText = () => {
    return get(_)(DEFAULT_ERROR_TEXT_KEY);
};
