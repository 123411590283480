import { __awaiter } from "tslib";
import { wrapPaginated } from 'src/api/query/fetchResult';
import { marketingApi } from 'src/api/apiClients';
import { errorHandlerWithDefault } from 'src/util/errorUtils';
import { applyStringParam } from 'src/api/paramUtils';
export const fetchMarketingTexts = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => marketingApi.getAllMarketingTexts(params, options);
    return wrapPaginated(func);
};
export const getMarketingText = (id, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield marketingApi.getMarketingText({ id }, options).catch(errorHandlerWithDefault(undefined));
});
export const createMarketingText = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield marketingApi
        .createMarketingText({
        createEditMarketingText: data
    })
        .catch(errorHandlerWithDefault(undefined));
});
export const updateMarketingText = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield marketingApi
        .updateMarketingText({
        id,
        createEditMarketingText: data
    })
        .catch(errorHandlerWithDefault(undefined));
});
export const deleteMarketingText = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield marketingApi.deleteMarketingText({ id }).catch(errorHandlerWithDefault(undefined));
});
export const findDailyAgencies = () => { };
const createFilterParams = (filterCriteria, pagination) => {
    const page = pagination.page - 1;
    const request = {
        page: Math.max(0, page),
        size: pagination.size,
        sort: [pagination.sort]
    };
    applyStringParam({
        value: filterCriteria.name,
        target: request,
        eqField: 'nameEq',
        likeField: 'nameLike'
    });
    if (filterCriteria.fromDate) {
        request.startDate = filterCriteria.fromDate;
    }
    if (filterCriteria.toDate) {
        request.endDate = filterCriteria.toDate;
    }
    if (filterCriteria.organizationId != undefined) {
        request.organizationId = filterCriteria.organizationId;
    }
    return request;
};
