/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrainNumberFromJSON, TrainNumberToJSON, } from './TrainNumber';
/**
 * Check if a given object implements the CreateEditMarketingText interface.
 */
export function instanceOfCreateEditMarketingText(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('trains' in value) || value['trains'] === undefined)
        return false;
    return true;
}
export function CreateEditMarketingTextFromJSON(json) {
    return CreateEditMarketingTextFromJSONTyped(json, false);
}
export function CreateEditMarketingTextFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'trains': (json['trains'].map(TrainNumberFromJSON)),
    };
}
export function CreateEditMarketingTextToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'textDe': value['textDe'],
        'textFr': value['textFr'],
        'textIt': value['textIt'],
        'textEn': value['textEn'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString().substring(0, 10)),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString().substring(0, 10)),
        'trains': (value['trains'].map(TrainNumberToJSON)),
    };
}
